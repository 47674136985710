import React from 'react';

import { Grid, Typography, List, ListItemText } from '@material-ui/core';

import { Layout } from '../components/layout/layout.component';
import { SEO } from '../components/seo/seo.component';

import './imprint.css';

const Imprint = () => (
  <Layout>
    <SEO title='CrefoTrust' />
    <div className='page-container'>
      <Grid container direction='column'>
        <Grid item lg={12}>
          <Typography variant='h2' component='h2'>
            Impressum{' '}
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <Typography variant='body1'>
            <strong>
              Dieses Internetangebot wird Ihnen zur Verfügung gestellt von
            </strong>
            <br />
            <br />
            Verband der Vereine Creditreform e.V.
            <br />
            Hammfelddamm 13
            <br />
            41460 Neuss
            <br />
            <br />
            Tel: +49 2131 109-0 <br />
            Fax: +49 2131 109-8000 
            <br />
            <br />
            <a href='mailto:kontakt@creditreform.de'>kontakt@creditreform.de</a>
            <br />
            <a href='https://www.creditreform.de'>www.creditreform.de</a>
          </Typography>
        </Grid>
        <br />
        <Grid item lg={12}>
          <Typography variant='body1'>
            <strong>
              Hinweis nach § 36 Verbraucherstreitbeilegungsgesetz (VSBG):
            </strong>
            <br />
            Der Verband der Vereine Creditreform e.V. nimmt nicht an einem
            Streitbeilegungsverfahren im Sinne des
            Verbraucherstreitbeilegungsgesetzes teil. Es besteht diesbezüglich
            keine gesetzliche Verpflichtung.
            <br />
            <strong>
              Versicherungsvermittlerregister gemäß § 11a Gewerbeordnung
            </strong>
            <br />
            Die Erlaubnis als Versicherungsvertreter mit Erlaubnisbefreiung nach
            § 34d Abs. 6 GewO (produktakzessorisch) wurde erteilt durch die
            zuständige Aufsichtsbehörde, die Industrie- und Handelskammer
            Mittlerer Niederrhein, Nordwall 39, 47798 Krefeld, an Verband der
            Vereine Creditreform e.V. unter der Registrierungs-Nr.
            D-Z42F-0FY1G-19. Die Registrierungs-Nr. wird geführt bei dem
            Deutschen Industrie- u. Handelskammertag, Breite Straße 29, 10187
            Berlin.
            <br />
            <strong>
              Journalistisch-redaktioneller Inhalt nach § 55 Abs. 2 RStV
            </strong>
            <br />
            Verantwortlich für Beiträge mit journalistisch-redaktionellem Inhalt
            nach § 55 Abs. 2 RStV ist Herr Patrik-Ludwig Hantzsch, Anschrift
            siehe oben
          </Typography>
        </Grid>
        <br />
        <Grid item lg={12}>
          <Typography variant='body1'>
            <strong>Vertretungsberechtigte</strong>
            <br />
            Präsidium
          </Typography>
          <List variant='body1' dense>
            <ListItemText>
              {' '}
              <span>-</span> Dr. Holger Bissel
            </ListItemText>
            <ListItemText>
              {' '}
              <span>-</span> Sebastian Schlegel
            </ListItemText>
            <ListItemText>
              {' '}
              <span>-</span> Thomas Schurk
            </ListItemText>
          </List>
          <Typography variant='body1'>
            Vereinsregister Neuss 
            <br />
            Vereinsregister Nr. 57 VR 506 
            <br />
            USt-IdNr.: DE 120 701 072 <br />
          </Typography>
        </Grid>
      </Grid>
    </div>
  </Layout>
);

export default Imprint;
